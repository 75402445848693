import { Card } from '@mui/material'
import React from 'react'

export default function ImageCard({ image = "", alt = "bm" ,style ={}}) {
   return (
      <>
       <Card style={{...style }}>
        <img
          style={{ width: "100%",
            height: "auto",
            marginTop: "10px",
            marginLeft: "10px",
            borderRadius: "20px",
          }}
          src={`${process.env.APP_URL || 'https://appcraftly.anncode.com'}${image}`}
          alt={alt}
        />
      </Card>
      </>
   )
}
