const Path = {
    HOME: "/",
    PRICING: "/pricing",
    CONTACT: "/contact",
    FEATURES: "/Features",
    ABOUT: "/About",
    TERMS_OF_SERVICE: "/TermOfService",
    PRIVACY_CONTENT: "/PrivacyContent",
    // FORM_SECTION: "/form", // Uncomment if needed
    // FEATURE_SECTION: "/featureSection", // Uncomment if needed
    TUTORIAL_PAGE: "/TutorialPage",
    DOCS:"/docs",
    MOBILE_TUTORIAL_PAGE: "/MobileViewTutorialPage",
    HELP_DOCS_CATEGORY: "/help-docs-category-page"
  };
  
  export default Path;
  