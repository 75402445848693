import React from "react";
import { Box, Typography, Link, Stack } from "@mui/material";
import { blue } from "@mui/material/colors";
import { Heading, ImageCard } from "../../../../ui-core";

import { 
   AppleStoreConnect, 
   copyBundleIdentifier, 
   goToAppsSection, 
   goToTheAppInformationSection, 
   loginTheAppStoreConnect, 
   memberShipDetails, 
   selectYourApp, 
   teamIdCopy 
} from "./images";
import Path from "../../../../routes/AppPaths";
import TutorialAndHelpPath from "../../../../routes/tutorialAndHelpPath";

const GetAppIdForIOS = () => {
   return (
      <Box pb={10}>
         <Stack spacing={3}>
            <Heading title="Get the App ID for Dynamic Links in iOS Applications" />

            {/* Step 1: Sign in to Apple Developer */}
            <Typography variant="h6" fontWeight="bold">
               Step 1: Sign in to Apple Developer
            </Typography>
            <Typography variant="body1">
               Visit the <Link href="https://developer.apple.com/" target="_blank" sx={{ color: blue[500], fontWeight: "bold" }}>Apple Developer Portal</Link> and sign in with your Apple ID.
            </Typography>
            <ImageCard image={AppleStoreConnect} alt="Apple Developer Login Page" />

            {/* Step 2: Find Membership Details */}
            <Typography variant="h6" fontWeight="bold">
               Step 2: Access Your Membership Details
            </Typography>
            <Typography variant="body1">
               After logging in, click on the **Membership Details** section from the top navigation bar.
               Scroll down to find your **Team ID**.
            </Typography>
            <ImageCard image={memberShipDetails} alt="Membership Details Page" />

            {/* Step 3: Copy the Team ID */}
            <Typography variant="h6" fontWeight="bold">
               Step 3: Copy Your Team ID
            </Typography>
            <Typography variant="body1">
               In the **Membership Details** section, locate your **Team ID** (e.g., `DMA4435S`).  
               Copy this ID for later use.
            </Typography>
            <ImageCard image={teamIdCopy} alt="Copy Team ID" />

            {/* Step 4: Sign in to App Store Connect */}
            <Typography variant="h6" fontWeight="bold">
               Step 4: Sign in to App Store Connect
            </Typography>
            <Typography variant="body1">
               Open a new tab and visit the <Link href="https://appstoreconnect.apple.com/" target="_blank" sx={{ color: blue[500], fontWeight: "bold" }}>App Store Connect</Link> page.  
               Sign in with the same Apple ID.
            </Typography>
            <ImageCard image={loginTheAppStoreConnect} alt="App Store Connect Login Page" />

            {/* Step 5: Navigate to the Apps Section */}
            <Typography variant="h6" fontWeight="bold">
               Step 5: Go to the Apps Section
            </Typography>
            <Typography variant="body1">
               Once logged in, click on **"Apps"** in the navigation menu.
            </Typography>
            <ImageCard image={goToAppsSection} alt="Go to Apps Section" />

            {/* Step 6: Select or Create an App */}
            <Typography variant="h6" fontWeight="bold">
               Step 6: Select or Create an App
            </Typography>
            <Typography variant="body1">
               - If you already have an app, select it from the list.  
               - If not, click on <Link href={`${Path.TUTORIAL_PAGE}/${TutorialAndHelpPath.GET_APP_ID_FOR_IOS}`}  target="_blank" sx={{ color: blue[500], fontWeight: "bold" }}>Create a New Application</Link> to set up a new one.
            </Typography>
            <ImageCard image={selectYourApp} alt="Select or Create an App" />

            {/* Step 7: Retrieve the Bundle ID */}
            <Typography variant="h6" fontWeight="bold">
               Step 7: Retrieve the Bundle ID
            </Typography>
            <Typography variant="body1">
               Navigate to the **App Information** section and scroll down to find your **Bundle ID**.  
               The Bundle ID is a unique identifier in the format:  
               **`com.xyz.appcraftly`**
            </Typography>
            <ImageCard image={goToTheAppInformationSection} alt="App Information Section" />
            <ImageCard image={copyBundleIdentifier} alt="Copy Bundle Identifier" />

            {/* Final Step: Construct the App ID */}
            <Typography variant="h6" fontWeight="bold">
               Step 8: Construct Your App ID
            </Typography>
            <Typography variant="body1">
               Now that you have both the **Team ID** and **Bundle ID**,  
               you can construct your **App ID** using the format:  
               **`TeamID.BundleID`**  
               Example:  
               **`DMA4435S.com.xyz.appcraftly`**
            </Typography>
         </Stack>
      </Box>
   );
};

export default GetAppIdForIOS;
