import React from "react";
import { Stack, Box, Typography, Link } from "@mui/material";
import { Heading, ImageCard } from "../../../../ui-core";
import { blue } from "@mui/material/colors";
import { AppleStoreConnectHome, ClickPlusIcon, createNewAppAndFillDetails, FillTheSku, OpenTheCreatedApp, RequestIpa, SelectBundleId } from "./images";

const UploadAnAppOnTheApplePlayStore = () => {
  return (
    <Box pb={20}>
      <Stack spacing={3}>

        <Heading title="Upload An App to the Apple App Store." />

        {/* intro */}
        <Typography variant="body1">
          While submitting your iOS app to the Apple App Store, it is essential to confirm that you have finished the app’s fundamental design and successfully linked it to your Apple Developer Account. If you haven’t connected it yet, kindly proceed with the{" "}<Link href="https://developer.apple.com/" target="_blank" sx={{ color: blue[500], fontWeight: "bold" }}>Apple Developer Portal</Link> and sign in with your Apple ID.
        </Typography>
        <Typography variant="body1">
          Now, you can proceed further with the App publishing to the Apple
          App Store.
        </Typography>

        <Box>
          <Typography variant="h6">
            Step:1 Request an app.
          </Typography>
          <ul>
            <li>
              <Typography variant="body1">
                Go to your <strong> Shopify dashboard </strong>.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Enter the <strong>App-craftly – mobile app</strong> from the Apps section.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Go to the <strong>App publishing </strong> page.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" >
                Click on the <strong>IOS Application</strong> Dropdown on the top of the page.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" >
                Click on <strong>Build and upload to TestFlight.</strong>
              </Typography>
            </li>
          </ul>
        </Box>
        <Typography variant="body1" >
          During this process, Appcraftly asks you to create an App in your App Store Connect using the Appcraftly app’s bundle ID. This step is necessary for your App Store Connect system to recognize and display the bundle ID so that you can use it when creating an app in the App Store Connect.
        </Typography>
        <ImageCard image={RequestIpa} alt="Apple Developer Login Page" />


        <Typography variant="h6">
          Step 2: Login to your App Store Connect.
        </Typography>
        <Box>
          <ul>
            <li>
              <Typography variant="body1">
                Login to your{" "}<Link href="https://appstoreconnect.apple.com/login" target="_blank" sx={{ color: blue[500], fontWeight: "bold" }}> App Store Connect</Link>
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Click on <strong>My Apps.</strong>
              </Typography>
            </li>
          </ul>
        </Box>
        <ImageCard image={AppleStoreConnectHome} />

        <Typography Typography variant="h6">
          Step 3: Create an App.
        </Typography>
        <Box>
          <ul>
            <li>
              <Typography variant="body1">
                Click the ‘+’ sign
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Click on <strong> New App </strong> to create a new app for your store.
              </Typography>
            </li>
            <Box mt={2} mb={2}><ImageCard image={ClickPlusIcon} /></Box>
            <li>
              <Typography variant="body1">
                A new app form will appear on the screen.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Check (√) the <strong> iOS </strong>for{" "}<strong> Platforms.</strong>
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Name your App
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Select your preferred default language.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Click on Bundle ID selector Dropdown.
              </Typography>
            </li>
            <Box mt={2} mb={2}><ImageCard image={createNewAppAndFillDetails} /></Box>
            <li>
              <Typography variant="body1">
                Now select <strong>Bundle ID</strong> that includes <strong>Appcraftly</strong> and the store name you’ve used for your app on the Shopify Dashboard.
              </Typography>
            </li>
            <Box mt={2} mb={2}><ImageCard image={SelectBundleId} /></Box>
            <li>
              <Typography variant="body1">
                Enter your SKU.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Select <strong>Full Access</strong>on user access.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Click <strong>Create.</strong>
              </Typography>
            </li>
            <Box mt={2} mb={2}> <ImageCard image={FillTheSku} alt="Fill the sku" /></Box>
            <Typography variant="body1">
              Your app will be created for the required store name and the bundle ID.
            </Typography>
            <Box mt={2} mb={2}><ImageCard image={OpenTheCreatedApp} alt="OpenTheCreatedApp" /></Box>
          </ul>
        </Box>
      </Stack>
    </Box>
  );
};

export default UploadAnAppOnTheApplePlayStore;
