import React from "react";
import { Box, Typography, Stack } from "@mui/material";
import { Heading, ImageCard } from "../../../../ui-core";
import { BuildFile, BuildFileIsReady, ClickNewReleaseButton, ConfirmRollout, CreateFirstApp, CreateNewRelease, CreateProductionRelease, CreateProductionRelease2, FillTheAppDetails, PublishYourAppStep, RequestForAPK, ReviewRelease, RolloutProduction, SetUpYourAppPlayConsole, ShopifyDashBoard } from "./images";

const UploadAnAppOnTheGooglePlayStore = () => {
  return (
    <Box pb={20}>
      <Stack spacing={3}>
        <Heading title="Upload an App to the Google Play Store." />

        {/* Step 1 */}
        <Typography variant="inherit" fontWeight="bold">
          Step 1: App Design and Customization
        </Typography>
        <Typography>Go to the Shopify dashboard and make your app ready for publishing.</Typography>
        <ImageCard image={ShopifyDashBoard} alt="Shopify Dashboard" />

        {/* Step 2 */}
        <Typography variant="inherit" fontWeight="bold">
          Step 2: Request for an App Build (APK)
        </Typography>
        <Typography>Go to <strong>App publishing</strong>.</Typography>
        <Typography>Click on the <strong>Android app</strong> dropdown.</Typography>
        <Typography>Click on <strong>Build Android APK</strong>.</Typography>
        <ImageCard image={RequestForAPK} alt="Build Android AAB" />
        <Typography>The bundle file (.aab) is in the build process. This may take approximately 20 minutes.</Typography>
        <ImageCard image={BuildFile} alt="Bundle File Processing" />
        <Typography>The App bundle file is ready. Now download the file.</Typography>
        <ImageCard image={BuildFileIsReady} alt="Bundle File Ready" />

        {/* Step 3 */}
        <Typography variant="inherit" fontWeight="bold">
          Step 3: Create an App inside Google Play Console
        </Typography>
        <Typography>Go to <strong>Google Play Console</strong>.</Typography>
        <Typography>Navigate to the <strong>All apps</strong> section.</Typography>
        <Typography>Click on <strong>Create app</strong>.</Typography>
        <ImageCard image={CreateFirstApp} alt="Google Play Console - Create App" />
        <Typography>Follow the instructions from the image below to create your app.</Typography>
        <ImageCard image={FillTheAppDetails} alt="Create App Instructions" />

        {/* Step 4 */}
        <Typography variant="inherit" fontWeight="bold">
          Step 4: Set Up Your App
        </Typography>
        <Typography>You will be redirected to the dashboard.</Typography>
        <Typography>Scroll down to the <strong>Set up Your App</strong> section.</Typography>
        <Typography>Fill in the details as shown in the image below.</Typography>
        <ImageCard image={SetUpYourAppPlayConsole} alt="Set Up Your App" />

        {/* Step 5 */}
        <Typography variant="inherit" fontWeight="bold">
          Step 5: Publish Your App
        </Typography>
        <Typography>Scroll down to the <strong>Publish your app on Google Play</strong> section.</Typography>
        <ImageCard image={PublishYourAppStep} alt="Publish App Section" />
        <Typography>Select the country/countries as per your requirement.</Typography>
        <Typography>Click on <strong>Create a new release</strong>.</Typography>
        <ImageCard image={CreateNewRelease} alt="Create New Release" />

        {/* Step 6 */}
        <Typography variant="inherit" fontWeight="bold">
          Step 6: Create a New Release
        </Typography>
        <Typography>Click on <strong>Create a new release</strong> under the <strong>Production</strong> section.</Typography>
        <ImageCard image={ClickNewReleaseButton} alt="New Release - Production" />
        <Typography>Upload your previously downloaded <strong>.aab</strong> file.</Typography>
        <Typography>If you haven’t already downloaded it from Shopify, do so and upload it here.</Typography>
        <Typography>Click on <strong>Save</strong>.</Typography>
        <ImageCard image={CreateProductionRelease} alt="Upload AAB File" />
        <ImageCard image={CreateProductionRelease2} alt="Save Release" />

        {/* Step 7 */}
        <Typography variant="inherit" fontWeight="bold">
          Step 7: Rollout to Production
        </Typography>
        <Typography>Click on <strong>Review release</strong>.</Typography>
        <ImageCard image={ReviewRelease} alt="Review Release" />
        <Typography>Click on <strong>Start Rollout to Production</strong>.</Typography>
        <Typography>Click on <strong>Rollout</strong>.</Typography>
        <ImageCard image={RolloutProduction} alt="Start Rollout" />
        <ImageCard image={ConfirmRollout} alt="Final Rollout" />

        {/* Final Notes */}
        <Typography>
          Once you have completed these steps, your app will be submitted to the Play Store for the review process.
          Please note that Google's review process may take up to a week. Once approved, your app will be available for
          download on the Play Store.
        </Typography>
      </Stack>
    </Box>
  );
};

export default UploadAnAppOnTheGooglePlayStore;
