import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import img1 from "../../assests/Group 1000004659.png";
import img2 from "../../assests/Group 1000004660.png";
import img3 from "../../assests/Group 1000004661.png";

import "./Animation.css"; // Import the CSS file containing animations
import { Container } from "@mui/system";

const logoStyle = {
  width: "200px",
  height: "auto",
  cursor: "pointer",
  transition: "transform 0.3s ease-in-out", // Adding a transition for smooth hover effect
};

const logoStyle1 = {
  width: "220px",
  height: "auto",
  cursor: "pointer",
  transition: "transform 0.3s ease-in-out", // Adding a transition for smooth hover effect
};

const Page1 = ({ mode, toggleColorMode }) => {

  const [animationCompleted, setAnimationCompleted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAnimationCompleted(true);
    }, 1000); // Delay the animation trigger to allow time for the component to render
    return () => clearTimeout(timeout);
  }, []);

  return (
    <Box backgroundColor="#1E5AFA" position="relative">
      <Container maxWidth="xl" sx={{ position:"relative", marginBottom: '250px' }}>
        <Grid Container sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', height:{xs:'440px' ,sm:'410px' ,md:'590px'}}}>
          <Box
            container
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              textAlign="center"
              width={{ xs: "100%", md: "900px", lg: "900px" }}
            >
              <Typography
                variant="h1"
                sx={{
                  position: "relative",
                  fontSize: { xs: "40px", md: "80px", lg: "80px" },
                  fontWeight: "600",
                  color: "white",
                  fontFamily: "sans-serif",
                  zIndex: "999",
                }}
              >
                Build a Mobile App for your Shopify Store
              </Typography>

              <Typography
                variant="h1"
                sx={{
                  position: "relative",
                  zIndex: "999",
                  fontSize:{xs:"15px" , md:'18px' ,lg:'18px'} ,
                  color: "white",
                  fontFamily: "sans-serif",
                  marginTop: "20px",
                  lineHeight: "27px",
                }}
              >
                Elevate conversions by 3X with an outstanding shopping journey, featuring push notifications, live shopping, and swift mobile checkout.
              </Typography>
            </Box>
          </Box>
          <Box
            container
            sx={{
              marginTop: {xs:"25px"  , md:'50px'},
              width:{xs:'' , md:'680px' , lg:'680px' },
              justifyContent:{xs:"center",md:"space-between"},
              display:"flex",
              alignItems:"center",  
              zIndex:"1"
            }}
          >
            <Box
              style={{
                animation: animationCompleted
                  ? "slideInLeft 2s ease forwards"
                  : "none",
              }}
            >
              <img src={img1} alt="log of appcraftly" style={logoStyle} />
            </Box>
            <Box
              display={{ xs: "none", md: "flex" }}
              style={{
                animation: animationCompleted
                  ? "slideInTop 2s ease forwards"
                  : "none",
              }}
            >
              <img src={img2} alt="log of appcraftly" style={logoStyle1} />
            </Box>
            <Box
              display={{ xs: "none", md: "flex" }}
              style={{
                animation: animationCompleted
                  ? "slideInRight 2s ease forwards"
                  : "none",
              }}
            >
              <img src={img3} alt="log of appcraftly" style={logoStyle} />
            </Box>
          </Box>
        </Grid>
        <Grid item
          sx={{
            position: "absolute",
            justifyContent: 'space-between',
            width: '100%',
            top: '-124px',
            zIndex:0,
            bottom: 0,
            display: { xs: "none", sm: "none", md: "flex" },
            width: '90%',
            left: '5%'
          }}
        >
          <Grid item
            style={{
              animation: animationCompleted
                ? "slideInTop 5s ease forwards"
                : "none",
            }}
            sx={{
              width: "2px",
              backgroundColor: "#326AFF",
            }}
          ></Grid>
          <Grid item
            style={{
              animation: animationCompleted
                ? "slideInTop 5s ease forwards"
                : "none",
            }}
            sx={{
              width: "2px",
              backgroundColor: "#326AFF",
            }}
          ></Grid>
          <Grid item
            style={{
              animation: animationCompleted
                ? "slideInTop 5s ease forwards"
                : "none",
            }}
            sx={{
              width: "2px",
              backgroundColor: "#326AFF",
            }}
          ></Grid>
          <Grid item
            style={{
              animation: animationCompleted
                ? "slideInTop 5s ease forwards"
                : "none",
            }}
            sx={{
              width: "2px",
              backgroundColor: "#326AFF",
            }}
          ></Grid>
          <Grid item
            style={{
              animation: animationCompleted
                ? "slideInTop 5s ease forwards"
                : "none",
            }}
            sx={{
              width: "2px",
              backgroundColor: "#326AFF",
            }}
          ></Grid>
          <Grid item
         
            style={{
              animation: animationCompleted
                ? "slideInTop 5s ease forwards" // Increase duration to 4s for example
                : "none",
            }}
            
            sx={{
              width: "2px",
              backgroundColor: "#326AFF",
            }}
          ></Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Page1;
