import React from "react";
import { Box, Typography, Link, Stack } from "@mui/material";
import { blue } from "@mui/material/colors";
import { Heading, ImageCard } from "../../../../ui-core";
import { copyBundleIdentifier, firebaseAddNewApp, firebaseAppConfigFile, firebaseClickAndroidApp, firebaseNext, firebaseProjectListPage, firebaseProjectSetting, firebaseRegisterIosApp, firebaseRegisterAppNext, firebaseYourApps, uploadFirebaseIOS } from "./images";


const ConnectFireBaseAccountIos = () => {
  return (
    <Box pb={10}>
      <Stack spacing={3}>
        <Heading title="Connect FireBase account with AppCraftly app for IOS" />

        <Typography variant="body1">
          1. Sign in to the <Link href={"https://console.firebase.google.com"} target="_blank" sx={{ color: blue[500], fontWeight: "bold" }}>Firebase</Link> console, then select your project.
        </Typography>
        <ImageCard image={firebaseProjectListPage} alt="Firebase Project List Page" />

        <Typography variant="body1">
          2. Click ⚙️, then select <strong>Project settings</strong>.
        </Typography>
        <ImageCard image={firebaseProjectSetting} alt="Firebase Project Settings" />
        <Box>
          <Typography variant="body1">
            3. In the <strong>‘Your apps’</strong> section, select the platform for the app you want to create.
          </Typography>
          <Typography variant="body1">
            If you don’t have any app in your project, scroll down and click on the ios icon.
          </Typography>
        </Box>
        <ImageCard image={firebaseYourApps} alt="Firebase Your Apps Page" />

        <Typography variant="body1">
          If you already have some apps in your project, scroll down and click on <strong>Add app</strong>.
        </Typography>
        <ImageCard image={firebaseAddNewApp} alt="Firebase Add New App" />

        <Typography variant="body1">
          Then, click on the IOS icon.
        </Typography>
        <ImageCard image={firebaseClickAndroidApp} alt="Firebase Click IOS App" />

        <Typography letterSpacing={1} variant="h6">👇 Now, Follow the required steps to add Firebase to your IOS app: 👇</Typography>

        <Typography variant="body1">1. Register App</Typography>
        <ImageCard image={firebaseRegisterIosApp} alt="Firebase Register IOS App" />
        <Typography variant="body1">
          To get the IOS Bundle Identifier, go to the AppCraftly mobile app on your Shopify Dashboard and copy the IOS Bundle Identifier from the box under App Settings.
        </Typography>
        <ImageCard image={copyBundleIdentifier} alt="Copy IOS Package Name" />

        <Typography variant="body1">2. Download the config file</Typography>
        <ImageCard image={firebaseAppConfigFile} alt="Download the IOS Firebase Config File" />

        <Typography variant="body1">3. Continue with ‘Next’.</Typography>
        <ImageCard image={firebaseNext} alt="Continue with Next" />

        <Typography variant="body1">4. App registration complete.</Typography>
        <ImageCard image={firebaseRegisterAppNext} alt="App Registration Complete" />

        <Typography variant="body1">5. Upload your ‘GoogleService-Info.plist’ file to Firebase account.</Typography>
        <Typography variant="body2" color="text.secondary">
          Go to your Shopify dashboard &gt; click on AppCraftly – Mobile app &gt; Settings &gt; Select Firebase Files Tab &gt; Upload and save Firebase configuration.
        </Typography>
        <ImageCard image={uploadFirebaseIOS} alt="Upload Firebase IOS Config" />

        <Typography variant="body1">Select the previously downloaded ‘GoogleService-Info.plist’ file and save Firebase credentials.</Typography>
        <Typography variant="body1">You’ve uploaded ‘GoogleService-Info.plist’ for IOS apps.</Typography>
      </Stack>
    </Box>
  );
};

export default ConnectFireBaseAccountIos

