import React from "react";
import { Box, Typography, Link, Stack } from "@mui/material";
import { blue } from "@mui/material/colors";
import { Heading, ImageCard } from "../../../../ui-core";
import { AddNewCertificate, AppleDeveloperAccount, CertificateAssistant, certificateDownload, ChooseCSRFile, CSRFileImage, DistributionCertificateUpdate, EditProfilePage, getNewDistributionCertificate, GoToCertificatePage, KeychainOpened, KeychainSavedAndDone, ProfilePage, ProfilesPage, RequestCertificate, SaveKeychainCertificate, SearchKeyChain, selectCsrFile, SelectIosDistribution } from "./images";


const RenewTheTosDistributionDevelopmentCertificate = () => {
  return (
    <Box pb={20}>
      <Stack spacing={3}>
        {/* Page Title */}
        <Heading level="h5">
          How to Renew Your iOS Distribution or Development Certificate on Apple (2025)
        </Heading>

        {/* Step 1: Keychain Access */}
        <Typography variant="h6">Step 1: Keychain Access</Typography>
        <Typography>Search for Keychain Access and open it.</Typography>
        <ImageCard image={SearchKeyChain} alt="Keychain Access" />

        <Typography>Keychain Access Panel will open.</Typography>
        <ImageCard image={KeychainOpened} alt="Keychain Access Panel" />

        <Typography>Go to Certificate Assistant &gt; Request a Certificate from a Certificate Authority…</Typography>
        <ImageCard image={RequestCertificate} alt="Request a Certificate" />

        <Typography>Fill in the personal information, check ‘Saved to Disk,’ and continue.</Typography>
        <ImageCard image={CertificateAssistant} alt="Certificate Assistant" />

        <Typography>Save the certificate file.</Typography>
        <ImageCard image={SaveKeychainCertificate} alt="Save Certificate Request" />

        <Typography>Click ‘Done.’</Typography>
        <ImageCard image={KeychainSavedAndDone} alt="Keychain Saved And Done" />

        {/* Step 2: Apple Developer Account */}
        <Typography variant="h6">Step 2: Apple Developer Account</Typography>
        <Typography> Go to{" "} <Link href="https://developer.apple.com/" target="_blank" color={blue[500]}> Apple Developer Account</Link>.
        </Typography>
        <ImageCard image={AppleDeveloperAccount} alt="Apple Developer" />

        <Typography>Go to Account &gt; Certificates.</Typography>
        <ImageCard image={GoToCertificatePage} alt="Apple Developer Account" />

        <Typography>Click on the ‘+’ sign to add a new certificate.</Typography>
        <ImageCard image={AddNewCertificate} alt="Certificates Page" />

        <Typography>Select ‘iOS Distribution (App Store & Ad Hoc)’ and continue.</Typography>
        <ImageCard image={SelectIosDistribution} alt="iOS Distribution" />

        <Typography>Upload the certificate signing request file and click Continue.</Typography>
        <ImageCard image={ChooseCSRFile} alt="Choose File" />
        <ImageCard image={CSRFileImage} alt="File Upload" />
        <ImageCard image={selectCsrFile} alt="File Selected" />

        <Typography>Download the new certificate and return to the ‘All Certificates’ page.</Typography>
        <ImageCard image={certificateDownload} alt="Download Certificate" />

        <Typography>
          A new iOS Distribution certificate will appear in the certificate list. Now, update your profiles.
        </Typography>
        <ImageCard image={ProfilePage} alt="Certificate to Profile" />

        <Typography>Go to the Profiles page and select the expiring certificate.</Typography>
        <ImageCard image={ProfilesPage} alt="Profiles" />

        <Typography>Click ‘Edit’ and select the newly created distribution certificate.</Typography>
        <ImageCard image={EditProfilePage} alt="Edit Profile" />

        <Typography>Save the changes.</Typography>
        <ImageCard image={getNewDistributionCertificate} alt="New Distribution" />
        <ImageCard image={DistributionCertificateUpdate} alt="Distribution Updated" />

        <Typography>
          The distribution certificate has been successfully replaced, and the expiry date has been extended.
        </Typography>
      </Stack>
    </Box>
  );
};

export default RenewTheTosDistributionDevelopmentCertificate;
