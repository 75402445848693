import React from "react";
import { Box, Typography, Container, Grid } from "@mui/material";
import AppSettingsAltIcon from "@mui/icons-material/AppSettingsAlt";
import DescriptionIcon from "@mui/icons-material/Description";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import RocketRoundedIcon from "@mui/icons-material/RocketRounded";
import {
  AppIntegrations,
  DocsListCategory,
  AppPublishing,
  InviteAppManagerForAssistance,
} from "../../content/HelpDocs";
import { useNavigate } from "react-router-dom";
import Path from "../../routes/AppPaths";

const HelpDocsList = () => {
  const navigate = useNavigate();
  const handleOpen = (docItems) => {
    navigate(`${Path.HELP_DOCS_CATEGORY}/${docItems.id}/${docItems.name}`, {
      state: docItems.data,
    });
  };

  const TutorialPage = [
    {
      id: "AppIntregation",
      name: "App Integration",
      icon: <AppSettingsAltIcon sx={{ fontSize: 150, color: "#1e5afa" }} />,
      data: AppIntegrations,
    },
    {
      id: "DocsListCategory",
      name: "Docs",
      icon: <DescriptionIcon sx={{ fontSize: 150, color: "#1e5afa" }} />,
      data: DocsListCategory,
    },
    {
      id: "AppPublishing",
      name: "App Publishing",
      icon: <RocketRoundedIcon sx={{ fontSize: 150, color: "#1e5afa" }} />,
      data: AppPublishing,
    },
    {
      id: "InviteAppManagerForAssistance",
      name: "Invite App Manager for Assistance",
      icon: (
        <AccountCircleRoundedIcon sx={{ fontSize: 150, color: "#1e5afa" }} />
      ),
      data: InviteAppManagerForAssistance,
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ padding: 3 }}>
      <Box sx={{ textAlign: "center", marginBottom: 4 }}>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
          Tutorial Page
        </Typography>
      </Box>

      <Grid container spacing={3} justifyContent="center">
        {TutorialPage.map((items) => {
          return (
            <HelpCard
              icon={items.icon}
              label={items.name}
              onPress={() => handleOpen(items)}
            />
          );
        })}
      </Grid>
    </Container>
  );
};

export default HelpDocsList;

const HelpCard = ({ icon, label, onPress }) => (
  <Grid item xs={12} sm={6}>
    <Box
      onClick={onPress}
      sx={{
        padding: 3,
        border: "2px solid #ddd",
        borderRadius: "10px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fff",
        transition: "0.3s",
        cursor: "pointer",
        "&:hover": {
          boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
          transform: "translateY(-2px)",
        },
        textAlign: "center",
      }}
    >
      {icon}
      <Typography
        variant="body1"
        paragraph
        sx={{ fontWeight: "bold", color: "#333" }}
      >
        {label}
      </Typography>
    </Box>
  </Grid>
);
