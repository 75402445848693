import React from 'react'
import mobileimg from "../../assests/group 2.png";
import { Container, Grid, Typography, List, ListItem, Box } from "@mui/material";

const AppcraftlyAbout = () => {
  return (
    <Container maxWidth='xl'>
      <Grid container spacing={2} marginTop={{ xs: '30px', md: '110px' }} backgroundColor="#F4EEED">
        <Grid item xs={12} md={6}>
          <Typography variant='h6' sx={{ fontSize: { xs: '30px', md: '50px' }, letterSpacing: '3px', fontWeight: '700', textAlign: 'left' }}>Appcraftly</Typography>
          <Box display='flex' justifyContent='center' marginRight={{ xs: '0', md: '80px' }}>
            <img
              style={{ width: "70%", maxWidth: "300px", height: "auto", marginTop: '30px' }}
              src={mobileimg}
              alt="Modern Technology"
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} display='flex' justifyContent='center'>
          <List>
            <ListItem>
              <Typography variant='h5'>We convert Shopify websites to Android and iOS apps.</Typography>
            </ListItem>
            <ListItem>
              <Typography variant='h5'>Bridge e-commerce and mobile platforms with advanced technology.</Typography>
            </ListItem>
            <ListItem>
              <Typography variant='h5'>Prioritize intuitive user experience and optimal performance on mobile.</Typography>
            </ListItem>
            <ListItem>
              <Typography variant='h5'>Ensure seamless integration for consistent brand experience.</Typography>
            </ListItem>
            <ListItem>
              <Typography variant='h5'>Enhance brand visibility, sales and business growth with mobile commerce solutions.</Typography>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Container>
  )
}

export default AppcraftlyAbout;
