import { Container, Typography, Grid } from "@mui/material";
import React from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Path from "../../routes/AppPaths";

function HelpDocsCategoryPage() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  console.log("location: ", location);

  const HandleNavigateToDocs = (item) => {
    navigate(`${Path.TUTORIAL_PAGE}/${item.path}`);
  };

  return (
    <Container sx={{ mt: 4, maxWidth: "800px" , paddingTop:"52px;",paddingBottom:"40px" }}>
      <h1>{params.name}</h1>
      <Grid container spacing={2}>
        {location.state.map((item, index) => (
          <Grid item xs={12} key={index}>
            <Typography
              variant="h6"
              sx={{
                cursor: "pointer",
                mb: 2,
                marginTop: "7px",
                fontWeight: 500,
                "&:hover": { color: "primary.main" }, // Add hover effect
              }}
              onClick={() => HandleNavigateToDocs(item)}
            >
              {item.name}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default HelpDocsCategoryPage;


