import React from 'react';
import HelpDocsList from '../../components/HelpDocsList';

const HelpDocsListPage =() => {
  return (
    <div>
      <HelpDocsList />
    </div>
  );
}
export default HelpDocsListPage;

