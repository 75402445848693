import React from "react";
import { Box, Typography, Link, Stack } from "@mui/material";
import { blue } from "@mui/material/colors";

import { Heading, ImageCard } from "../../../../ui-core";
import { 
  ClickContinue, 
  CreateProjectAndContinue, 
  DownloadSdkJsonFile, 
  EnterNameOfTheProject, 
  GenerateNewPrivateKey, 
  GoogleAnalysis, 
  ProjectSettings, 
  ServiceAccountTab, 
  UploadAdminSdkFile, 
  welcomeFirebaseApp 
} from "./images";

const GetAdminSdkFileAndUploadFile = () => {
  return (
    <Box pb={10}>
      <Stack spacing={3}>
        <Heading title="Integrate Firebase Account with Appcraftly Server (Admin SDK)" />

        <Typography variant="h6" fontWeight="bold">
          Step 1: Create a Firebase Project
        </Typography>
        <Typography variant="body1">
          Sign in to{" "} 
          <Link href="https://console.firebase.google.com/u/0/" target="_blank" sx={{ color: blue[500], fontWeight: "bold" }}>
            Firebase Console
          </Link>{" "} 
          and create a new project.
        </Typography>
        <ImageCard image={welcomeFirebaseApp} alt="Firebase Welcome Page" />

        <Typography variant="body1">
          Follow these steps to set up your project:
        </Typography>
        <Box component="ul">
          <li>
            <Typography variant="body1">
              Add a <strong>Project Name</strong>.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Accept the <strong>Firebase Terms</strong> and click <strong>Continue</strong>.
            </Typography>
          </li>
        </Box>
        <ImageCard image={EnterNameOfTheProject} alt="Enter Project Name" />

        <Typography variant="body1">
          Next, enable <strong>Google Analytics</strong> and click <strong>Continue</strong>.
        </Typography>
        <ImageCard image={GoogleAnalysis} alt="Enable Google Analytics" />

        <Typography variant="body1">
          Click <strong>Create Project</strong> to finalize the setup.
        </Typography>
        <ImageCard image={CreateProjectAndContinue} alt="Create Firebase Project" />

        <Typography variant="body1">
          Once your project is created, click <strong>Continue</strong>.
        </Typography>
        <ImageCard image={ClickContinue} alt="Continue to Firebase Dashboard" />

        
        <Typography variant="h6" fontWeight="bold">
          Step 2: Access Project Settings
        </Typography>
        <Typography variant="body1">
          Open **Project Settings** by clicking the <strong>⚙️ Settings</strong> icon.
        </Typography>
        <ImageCard image={ProjectSettings} alt="Firebase Project Settings" />

        
        <Typography variant="h6" fontWeight="bold">
          Step 3: Generate a Private Key
        </Typography>
        <Typography variant="body1">
          Navigate to the **Service Accounts** tab and click <strong>Generate New Private Key</strong>.
        </Typography>
        <ImageCard image={ServiceAccountTab} alt="Service Accounts Page" />

      
        <Typography variant="h6" fontWeight="bold">
          Step 4: Download the Private Key
        </Typography>
        <Typography variant="body1">
          Click **Generate Key**, and the key file will be downloaded automatically.
        </Typography>
        <ImageCard image={GenerateNewPrivateKey} alt="Generate Firebase Private Key" />
        <ImageCard image={DownloadSdkJsonFile} alt="Download Firebase Key" />

        <Typography variant="body1">
          Now, upload this file to the <strong>Edit Firebase Configuration</strong> section in Appcraftly.
        </Typography>

        
        <Typography variant="h6" fontWeight="bold">
          Step 5: Upload Your Firebase Key to Appcraftly
        </Typography>
        <Typography variant="body1">
          Follow these steps to upload your Firebase key:
        </Typography>
        <Box component="ul">
          <li>
            <Typography variant="body1">Go to your **Shopify Dashboard**.</Typography>
          </li>
          <li>
            <Typography variant="body1">Click on **Appcraftly – Mobile App**.</Typography>
          </li>
          <li>
            <Typography variant="body1">Open **Settings**.</Typography>
          </li>
          <li>
            <Typography variant="body1">Click on **Edit Firebase Configuration**.</Typography>
          </li>
        </Box>
        <ImageCard image={UploadAdminSdkFile} alt="Edit Firebase Configuration" />

        <Typography variant="body1">
          You have successfully uploaded the **Firebase Admin SDK JSON file** for integration with Appcraftly.
        </Typography>

      </Stack>
    </Box>
  );
};

export default GetAdminSdkFileAndUploadFile;
