import React from "react";
import { Route } from "react-router-dom";
import TutorialPage from "../../view/Tutorial";
import Path from "../AppPaths";

import TutorialAndHelpPath from "../tutorialAndHelpPath";

import {
  ConnectFirebaseAccountWithAndroid,
  ConnectFireBaseAccountIos,
  DynamicLinkForAndroidAndIos,
  GetAppIdForIOS,
  GetAdminSdkFileAndUploadFile,
  FirebaseIntegrationGuide,
  RenewTheTosDistributionDevelopmentCertificate,
  UploadAnAppOnTheGooglePlayStore,
  UploadAnAppOnTheApplePlayStore,
  UpdateShaConfigsIntoFireBase,
 
} from "../../components/help-and-tutorial-pages/DocsPage";

import { AppManagerForGooglePlayConsole, AppStoreConnect } from "../../components/help-and-tutorial-pages/InviteAppManagerAssistant";
import { JudgeMeDocs, StorefrontToken } from "../../components/help-and-tutorial-pages/settings-docs";
import { PublishingAndroidAppForFirstTime, PublishingIosAppForTheFirstTime } from "../../components/help-and-tutorial-pages/AppPublishing";


const tutorialRoutes = [
  <Route key="tutorial" path={Path.TUTORIAL_PAGE} element={<TutorialPage />}>
    {/* Invite App Manager For Assistance */}
    <Route path={TutorialAndHelpPath.INVITE_GOOGLE_PLAY_CONSOLE} element={<AppManagerForGooglePlayConsole />} />
    <Route path={TutorialAndHelpPath.INVITE_APP_STORE_CONNECT} element={<AppStoreConnect />} />

    {/* App Integrations */}
    <Route  path={TutorialAndHelpPath.INTEGRATION_FIREBASE_FOR_IOS_ANDROID} element={<FirebaseIntegrationGuide />}/>

    {/*Help Docs  */}
    <Route path={TutorialAndHelpPath.CONNECT_FIREBASE_ACCOUNT_WITH__ANDROID} element={<ConnectFirebaseAccountWithAndroid />} />
    <Route path={TutorialAndHelpPath.CONNECT_FIREBASE_ACCOUNT_WITH_FOR_IOS} element={<ConnectFireBaseAccountIos />} />
    <Route path={TutorialAndHelpPath.DYNAMIC_LINK_ANDROID_IOS} element={<DynamicLinkForAndroidAndIos />} />
    <Route path={TutorialAndHelpPath.GET_APP_ID_FOR_IOS} element={<GetAppIdForIOS />} />
    <Route path={TutorialAndHelpPath.GET_THE_FIREBASE_ADMIN_ADK_AND_FILE_UPLOAD} element={<GetAdminSdkFileAndUploadFile />} />
    <Route path={TutorialAndHelpPath.RENEW_YOUR_IOS_DISTRIBUTION_APPLE_2025} element={<RenewTheTosDistributionDevelopmentCertificate />}/>
    <Route path={TutorialAndHelpPath.UPLOAD_AN_APP_TO_THE_GOOGLE_PLAY_STORE} element={<UploadAnAppOnTheGooglePlayStore />}/>
    <Route path={TutorialAndHelpPath.UPLOAD_AN_APP_TO_THE_APPLE_APP_STORE} element={<UploadAnAppOnTheApplePlayStore />}/>
    <Route path={TutorialAndHelpPath.UPDATE_SHA_CONFIGS_IN_THE_FIREBASE} element={<UpdateShaConfigsIntoFireBase />}/>

    {/* k */}
    <Route path={TutorialAndHelpPath.JUDGE_ME_IMPLEMENTATION} element={<JudgeMeDocs />}/>
    <Route path={TutorialAndHelpPath.GET_THE_STOREFRONT_STORE_TOKEN} element={<StorefrontToken />}/>
    {/*  */}
    <Route path={TutorialAndHelpPath.PUBLISHING_ANDROID_APP_FIRST_TIME_WITH_APPCRAFTLY} element={<PublishingAndroidAppForFirstTime />}/>
    <Route path={TutorialAndHelpPath.PUBLISHING_IOS_APP_FIRST_TIME_WITH_APPCRAFTLY} element={<PublishingIosAppForTheFirstTime />}/>
  </Route>,
];

export default tutorialRoutes;
