import React, { useState, useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { Grid, Typography, Container, IconButton, Collapse, Stack } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AppIntegrations, DocsListCategory, AppPublishing, InviteAppManagerForAssistance } from "../../content/HelpDocs";

const categories = {
    appIntegrations: { title: "App Integrations", items: AppIntegrations },
    docs: { title: "Docs", items: DocsListCategory },
    appPublishing: { title: "App Publishing", items: AppPublishing },
    inviteAppManager: { title: "Invite App Manager for Assistance", items: InviteAppManagerForAssistance },
};

const DropdownSection = ({ title, items, isOpen, onToggle, selectedItem, onSelect }) => (
    <>
        <Grid container alignItems="center" sx={{ cursor: "pointer", display: "flex", justifyContent: "space-between" }} onClick={onToggle}>
            <Typography variant="subtitle1">{title}</Typography>
            <IconButton sx={{ transform: isOpen ? "rotate(180deg)" : "rotate(0deg)", transition: "transform 0.3s ease" }}>
                <ExpandMoreIcon />
            </IconButton>
        </Grid>

        <Collapse in={isOpen}>
            <Stack mt={1.5} gap={1}>
                {items.map((item, index) => (
                    <Typography
                        key={index}
                        variant="subtitle2"
                        sx={{
                            fontWeight: "bold",
                            cursor: "pointer",
                            color: selectedItem === index ? "#1E5AFA" : "gray",
                            "&:hover": { color: "lightgray" },
                        }}
                        onClick={() => onSelect(item.path, index)}
                    >
                        📄 {item.name}
                    </Typography>
                ))}
            </Stack>
        </Collapse>
    </>
);

const TutorialPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname.split("/").pop();
    const [state, setState] = useState({
        openCategory: null,
        selectedItem: {},
    });

    useEffect(() => {
        for (const category in categories) {
            const index = categories[category].items.findIndex((item) => item.path === currentPath);
            if (index !== -1) {
                setState({ openCategory: category, selectedItem: { [category]: index } });
                return;
            }
        }
        setState((prev) => ({ ...prev, openCategory: null }));
    }, [currentPath]);

    const toggleDropdown = (category) => {
        setState((prev) => ({ ...prev, openCategory: prev.openCategory === category ? null : category }));
    };

    const handleItemClick = (path, index, category) => {
        navigate(path);
        setState({ openCategory: category, selectedItem: { [category]: index } });
    };

    return (
        <Container maxWidth="xl">
            <Grid container sx={{ height: "100%", mt: { xs: 4, sm: 0 } }}>
                {/* Sidebar */}
                <Grid
                    item
                    xs={12}
                    sm={5}
                    lg={4}
                    sx={{
                        display: { xs: "none", md: "flex" },
                        flexDirection: "column",
                        backgroundColor: "background.paper",
                        borderRight: { md: "1px solid" },
                        borderColor: { md: "divider" },
                        alignItems: "start",
                        px: 3,
                        pt: 10,
                    }}
                >
                    {Object.entries(categories).map(([key, { title, items }]) => (
                        <DropdownSection
                            key={key}
                            title={title}
                            items={items}
                            isOpen={state.openCategory === key}
                            onToggle={() => toggleDropdown(key)}
                            selectedItem={state.selectedItem[key]}
                            onSelect={(path, index) => handleItemClick(path, index, key)}
                        />
                    ))}
                </Grid>

                {/* Main Content Section */}
                <Grid item xs={12} sm={7} lg={8} sx={{ display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "center", pt: 12, px: { xs: 4, sm: 10 } }}>
                    <Outlet />
                </Grid>
            </Grid>
        </Container>
    );
};

export default TutorialPage;
