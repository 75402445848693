import React from "react";
import { Box, Typography, Link, Stack } from "@mui/material";
import { Heading, ImageCard } from "../../../../ui-core";
import { blue } from "@mui/material/colors";
import { AndroidDeepLinkFile, AppId_image, DeepLinkFileIOS, EnterDomain } from "./images";
import TutorialAndHelpPath from "../../../../routes/tutorialAndHelpPath";
import Path from "../../../../routes/AppPaths";

const DynamicLinkForAndroidAndIos = () => {
   return (
      <Box pb={10}>
         <Stack spacing={3}>
            <Heading title="Connect Firebase with AppCraftly for iOS & Android" />

            {/* Section 1: Setting up Firebase Dynamic Links */}
            <Typography variant="inherit" fontWeight="bold">
               Step 1: Configure Your Firebase Project
            </Typography>
            <Box >
               <Typography variant="body1">
                  Before enabling Dynamic Links, ensure that your Firebase project is configured correctly. You need a valid domain or hosting URL, such as: <strong> "https://appcraftly.anncode.com/"</strong>
               </Typography>
               <Typography variant="body1">
                  Navigate to <strong>Settings ⚙️ → Dynamic Links</strong> and enter your domain in the designated field.
               </Typography>
            </Box>
            <ImageCard image={EnterDomain} alt="Enter Domain" />

            {/* Section 2: Enabling Dynamic Links for iOS */}
            <Typography variant="inherit" fontWeight="bold">
               Step 2: Enable Dynamic Links for iOS
            </Typography>
            <Box>
               <Typography variant="body1">
                  To enable Deep Links for iOS, you need the **App ID**. You can obtain it from the <br/>
                  <Link href={`${Path.TUTORIAL_PAGE}/${TutorialAndHelpPath.GET_APP_ID_FOR_IOS}`} target="_blank" sx={{ color: blue[500], fontWeight: "bold" }}>
                     Get the app ID (Apple connect)
                  </Link>.
               </Typography>
               <Typography variant="body1">
                  Once you have the App ID:
               </Typography>
               <ul>
                  <li>Enter the Apple App ID.</li>
                  <li>Check the **iOS** checkbox.</li>
                  <li>Click **Save** to apply changes.</li>
               </ul>
            </Box>
            <ImageCard image={AppId_image} alt="Apple App ID" />

            {/* Section 3: Configuring Apple-App-Site-Association File */}
            <Typography variant="inherit" fontWeight="bold">
               Step 3: Configure the apple-app-site-association File
            </Typography>
            <Box>
               <Typography variant="body1">
                  Once Dynamic Links are enabled, you need to configure iOS deep linking.
               </Typography>
               <Typography variant="body1">
                  Follow these steps:
               </Typography>
               <ol>
                  <li>Navigate to the root directory of your host domain:
                     <strong> (https://appcraftly.anncode.com) </strong></li>
                  <li>Create a file named **apple-app-site-association**.</li>
                  <li>Copy and paste the required JSON configuration into this file.</li>
               </ol>
            </Box>
            <ImageCard image={DeepLinkFileIOS} alt="apple-app-site-association file" />

            {/* Section 4: Enabling Deep Links for Android */}
            <Typography variant="inherit" fontWeight="bold">
               Step 4: Enable Deep Links for Android
            </Typography>
            <Box>
               <Typography variant="body1">
                  To enable Deep Links for Android, you first need to build your project.
                  If your build is ready, follow these steps:
               </Typography>
               <ul>
                  <li>Check the **Android** checkbox.</li>
                  <li>Click **Save** to confirm.</li>
                  <li>Follow the on-screen instructions to configure Deep Linking.</li>
               </ul>
            </Box>

            {/* Section 5: Configuring AssetLinks.json File */}
            <Typography variant="inherit" fontWeight="bold">
               Step 5: Configure the assetlinks.json File
            </Typography>
            <Box>
               <Typography variant="body1">
                  To complete the setup, you must create an **assetlinks.json** file for Android.
               </Typography>
               <ol>
                  <li>Navigate to the root directory of your host domain:
                     <strong> (https://anncode.com) </strong></li>
                  <li>Create a new folder named **.well-known**.</li>
                  <li>Inside this folder, create a file named **assetlinks.json**.</li>
                  <li>Copy and paste the required JSON configuration into this file.</li>
               </ol>
            </Box>
            <ImageCard image={AndroidDeepLinkFile} alt=".well-known/assetlinks.json" />
         </Stack>
      </Box>
   );
};


export default DynamicLinkForAndroidAndIos