import { Divider, Typography } from '@mui/material'
import React from 'react'

export default function Heading({ title = "", isDivider = true }) {
   return (
      <>
         <Typography sx={{ fontSize: "24px", fontWeight: "700", lineHeight: "28px", }}>
            {title}
         </Typography>
         {isDivider &&
            <Divider />
         }
      </>
   )
}
