import axios from "axios";

const baseURL = `http://localhost:8081/api/v1`;
// const baseURL = `${process.env.REACT_APP_GATEWAY_URL}/api/v1`;


export const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 100000,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

