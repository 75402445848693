import TutorialAndHelpPath from "../routes/tutorialAndHelpPath";

export const InviteAppManagerForAssistance = [
  {
    id: TutorialAndHelpPath.INVITE_GOOGLE_PLAY_CONSOLE,
    name: "Invite Appcraftly App Manager to my Android developer account (Google Play Console).",
    path: TutorialAndHelpPath.INVITE_GOOGLE_PLAY_CONSOLE,
  },
  {
    id: TutorialAndHelpPath.INVITE_APP_STORE_CONNECT,
    name: "Invite Appcraftly App Manager to my Apple developer Account (App Store Connect).",
    path: TutorialAndHelpPath.INVITE_APP_STORE_CONNECT
  },
];

export const AppIntegrations = [
  {
    id: TutorialAndHelpPath.INTEGRATION_FIREBASE_FOR_IOS_ANDROID,
    name: "Integrate Firebase with Appcraftly and Configure for iOS and Android Apps.",
    path: TutorialAndHelpPath.INTEGRATION_FIREBASE_FOR_IOS_ANDROID,
  }
];


export const DocsListCategory = [
  //new
  {
    id: TutorialAndHelpPath.CONNECT_FIREBASE_ACCOUNT_WITH__ANDROID,
    name: "Connect Firebase account with appcraftly app for Android application.",
    path: TutorialAndHelpPath.CONNECT_FIREBASE_ACCOUNT_WITH__ANDROID,
  },
  {
    id: TutorialAndHelpPath.CONNECT_FIREBASE_ACCOUNT_WITH_FOR_IOS,
    name: "Connect Firebase account with appcraftly app for IOS application.",
    path: TutorialAndHelpPath.CONNECT_FIREBASE_ACCOUNT_WITH_FOR_IOS,
  },
  {
    id: TutorialAndHelpPath.DYNAMIC_LINK_ANDROID_IOS,
    name: "Enable the Dynamic/Deep link in the AppCraftly app for android and IOS",
    path: TutorialAndHelpPath.DYNAMIC_LINK_ANDROID_IOS,
  },
  {
    id: TutorialAndHelpPath.GET_APP_ID_FOR_IOS,
    name: "Get the IOS app id for enabling the Dynamic/deep link",
    path: TutorialAndHelpPath.GET_APP_ID_FOR_IOS,
  },
  {
    id: TutorialAndHelpPath.GET_THE_FIREBASE_ADMIN_ADK_AND_FILE_UPLOAD,
    name: "Get the the firebase Admin SDK file for the app notification",
    path: TutorialAndHelpPath.GET_THE_FIREBASE_ADMIN_ADK_AND_FILE_UPLOAD,
  },
  {
    id: TutorialAndHelpPath.RENEW_YOUR_IOS_DISTRIBUTION_APPLE_2025,
    name: "How to Renew Your IOS Distribution Or Development Certificate on Apple-2025?",
    path: TutorialAndHelpPath.RENEW_YOUR_IOS_DISTRIBUTION_APPLE_2025,
  },
  {
    id: TutorialAndHelpPath.UPLOAD_AN_APP_TO_THE_GOOGLE_PLAY_STORE,
    name: "Upload an app to the Google Play Store.",
    path: TutorialAndHelpPath.UPLOAD_AN_APP_TO_THE_GOOGLE_PLAY_STORE,
  },
  {
    id: TutorialAndHelpPath.UPLOAD_AN_APP_TO_THE_APPLE_APP_STORE,
    name: "Upload An App to the Apple App Store.",
    path: TutorialAndHelpPath.UPLOAD_AN_APP_TO_THE_APPLE_APP_STORE,
  },
  {
    id: TutorialAndHelpPath.JUDGE_ME_IMPLEMENTATION,
    name: "Implement the the Judge.Me app in the Appcraftly.",
    path: TutorialAndHelpPath.JUDGE_ME_IMPLEMENTATION,
  },
  {
    id: TutorialAndHelpPath.GET_THE_STOREFRONT_STORE_TOKEN,
    name: "Get the StoreFront Access token For Appcraftly.",
    path: TutorialAndHelpPath.GET_THE_STOREFRONT_STORE_TOKEN,
  },
  {
    id: TutorialAndHelpPath.UPDATE_SHA_CONFIGS_IN_THE_FIREBASE,
    name: "Update the android SHA configs (SHA-1 and SHA-265) in the firebase.",
    path: TutorialAndHelpPath.UPDATE_SHA_CONFIGS_IN_THE_FIREBASE,
  },
];

export const AppPublishing = [
  {
    id: TutorialAndHelpPath.PUBLISHING_ANDROID_APP_FIRST_TIME_WITH_APPCRAFTLY,
    name: "Publishing Android App for the first time with appcraftly app",
    path: TutorialAndHelpPath.PUBLISHING_ANDROID_APP_FIRST_TIME_WITH_APPCRAFTLY,
  },
  {
    id: TutorialAndHelpPath.PUBLISHING_IOS_APP_FIRST_TIME_WITH_APPCRAFTLY,
    name: "Publishing ios app for the first time with appcraftly.",
    path: TutorialAndHelpPath.PUBLISHING_IOS_APP_FIRST_TIME_WITH_APPCRAFTLY,
  },

];

