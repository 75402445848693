import React from "react";
import { Box, Typography, Link, Stack } from "@mui/material";
import { Heading, ImageCard } from "../../../../ui-core";
import { DialogueBoxThatAppeared, EmailPermission, googleSignIn, InviteNewUser, LastInvitationSentImg, UsersPermissions } from "./images";
import { InviteAppManagerForAssistance } from "../../../../content/HelpDocs";
import { blue } from "@mui/material/colors";

const AppManagerForGooglePlayConsole = () => {
  return (
    <Box pb={20}>
      <Stack spacing={3}>
        <Heading title={InviteAppManagerForAssistance[0].name} />

        <Typography variant="body1">
          To invite AppCraftly as an app manager, follow these steps:
        </Typography>

        <Typography variant="body1">
          1. Go to the <Link href="https://developer.android.com/distribute/console" target="_blank" style={{ color: blue[500] ,fontWeight:'bolder'}}>Google Play Console</Link> and sign in to your developer account.
        </Typography>
        <ImageCard image={googleSignIn} alt="Google Play Console sign-in page" />

        <Typography variant="body1">
          2. In the Play Console dashboard, navigate to <strong>"Users and Permissions."</strong> 
        </Typography>
        <ImageCard image={UsersPermissions} alt="Users and Permissions section" />

        <Typography variant="body1">
          3. Click on the three-dot menu and select <strong>"Invite new user."</strong>
        </Typography>
        <ImageCard image={InviteNewUser} alt="Invite new user option" />

        <Typography variant="body1">
          4. In the user details section, enter the following email ID: <strong>rahul@anncode.com</strong>.
          Then, under <strong>Account Permissions</strong>, select <strong>"Admin (All Permissions)."</strong>
          Finally, click <strong>"Invite user."</strong>
        </Typography>
        <ImageCard image={EmailPermission} alt="Admin permissions selection" />

        <Typography variant="body1">
          5. In the confirmation dialog that appears, click <strong>"Send Invite."</strong>
        </Typography>
        <ImageCard image={DialogueBoxThatAppeared} alt="Send invite confirmation dialog" />

        <Typography variant="body1">
          6. The invitation has been successfully sent.
        </Typography>
        <ImageCard image={LastInvitationSentImg} alt="Invitation sent confirmation" />
      </Stack>
    </Box>
  );
};

export default AppManagerForGooglePlayConsole;
