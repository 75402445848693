import React from "react";
import { Box, Typography, Link, Stack } from "@mui/material";
import { blue } from "@mui/material/colors";
import { Heading } from "../../../../ui-core";

const PublishingIosAppForTheFirstTime = () => {
  return (
    <Box pb={10}>
      <Stack spacing={3}>
        <Heading title="Publish Your First iOS App on the Apple App Store" />

        <Typography variant="body1">
          Publishing your app on the Apple App Store allows you to reach a vast audience of iOS users. This guide will walk you through the process of uploading and publishing your iOS app.
        </Typography>

        {/* Step 1 */}
        <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: 2 }}>
          Step 1: Preparing and Uploading Your App to the Apple App Store 📤
        </Typography>
        <Typography variant="body1">
          Now that your developer account is connected, follow these steps to upload your app to the Apple App Store:
        </Typography>
        <Typography variant="body1">
          1. Access the detailed guide at
          <Link href="https://appcraftly.anncode.com/TutorialPage/upload-an-app-to-the-apple-app-store" target="_blank"sx={{ color: blue[500], fontWeight: "bold" }}>{" "} Detailed instructions {" "} </Link>
          for a comprehensive walkthrough of uploading your app using App Store Connect.
        </Typography>

        <Typography variant="body1" sx={{ marginTop: 1 }}>
          2. Ensure you have the latest version of Xcode installed.
        </Typography>
        <Typography variant="body1" sx={{ marginTop: 1 }}>
          3. Create a new app in App Store Connect using a unique bundle ID.
        </Typography>
        <Typography variant="body1" sx={{ marginTop: 1 }}>
          4. Prepare your app build using Xcode and submit it to TestFlight for testing.
        </Typography>
        <Typography variant="body1" sx={{ marginTop: 1 }}>
          5. Set pricing and availability options according to your preferences.
        </Typography>
        <Typography variant="body1" sx={{ marginTop: 1 }}>
          6. Review and confirm your app submission.
        </Typography>

        {/* Step 2 */}
        <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: 2 }}>
          Step 2: App Review and Publication ✅
        </Typography>
        <Typography variant="body1">
          After submitting your app, it will undergo a review process conducted by the Apple App Store team. Here’s what you can expect:
        </Typography>

        <Typography variant="body1" sx={{ marginTop: 1 }}>
          1. The review process typically takes several business days to a week. You’ll receive a notification indicating the status of your app’s publication.
        </Typography>
        <Typography variant="body1" sx={{ marginTop: 1 }}>
          2. During this time, the App Store team will evaluate your app for compliance with their guidelines.
        </Typography>
        <Typography variant="body1" sx={{ marginTop: 1 }}>
          3. If any issues or guideline violations are found, follow the instructions provided to address them and resubmit your app for review.
        </Typography>
        <Typography variant="body1" sx={{ marginTop: 1 }}>
          4. Upon successful review, your app will be published on the Apple App Store and made available to users.
        </Typography>

        <Typography variant="body2" align="center" sx={{ fontWeight: "bold", color: "green", marginTop: 2 }}>
          🎉 Congratulations! You have successfully published your iOS app on the Apple App Store.
        </Typography>
      </Stack>
    </Box>
  );
};

export default PublishingIosAppForTheFirstTime;
