import {
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  FormHelperText,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

const ContactForm = ({ onClose }) => {
  const initialValues = {
    name: "",
    phone: "",
    email: "",
    text: "",
  };

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .matches(/^[A-Za-z\s]*$/, "Please enter a valid name")
      .min(3, "Name must be at least 3 characters")
      .max(20, "Name must be at most 20 characters")
      .required("Name is required"),
    phone: yup
      .number()
      .required("Please enter number")
      .test("is-ten-digits", "Number must be exactly 10 digits", (value) => {
        if (value) {
          const stringValue = String(value);
          return stringValue.length === 10;
        }
        return false;
      }),
    email: yup
      .string()
      .email("Please enter a valid email address")
      .required("Email is required"),

    // email: yup.email().required("Email is required"),
  });

  const onSubmit = async (values, { resetForm }) => {
    console.log("values", values);
    resetForm();
    formik.setFieldValue("checkbox", false);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  return (
    <Box>
      <Box fullWidth sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          onClick={onClose}
          sx={{
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        ></Button>
      </Box>

      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography
              variant="h5"
              sx={{ fontSize: "12px", paddingBottom: "10px" }}
            >
              Your Name *
            </Typography>
            <TextField
              id="nameField"
              type="text"
              variant="outlined"
              fullWidth
              placeholder="Name"
              sx={{
                "& input": {
                  padding: "17px 14px",
                },
                "& .MuiFormHelperText-root": {
                  margin: 0,
                },
                bgcolor: "#ebf6fd",
              }}
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
            />
            {formik.touched.name && formik.errors.name && (
              <FormHelperText
                sx={{
                  margin: 0,
                  color: "red",
                  fontSize: "12px",
                }}
              >
                {formik.errors.name}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: "12px", paddingBottom: "10px" }}>
              Your Phone *
            </Typography>
            <TextField
              id="numberField"
              type="tel"
              variant="outlined"
              fullWidth
              placeholder="Phone"
              sx={{
                "& input": {
                  padding: "17px 14px",
                },
                "& .MuiFormHelperText-root": {
                  margin: 0,
                },
                bgcolor: "#ebf6fd",
              }}
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
            />
            {formik.touched.phone && formik.errors.phone && (
              <FormHelperText
                sx={{
                  margin: 0,
                  color: "red",
                  fontSize: "12px",
                }}
              >
                {formik.errors.phone}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: "12px", paddingBottom: "10px" }}>
              Email*
            </Typography>
            <TextField
              id="numberField"
              type="email"
              variant="outlined"
              fullWidth
              placeholder="Email"
              sx={{
                "& input": {
                  padding: "17px 14px",
                },
                "& .MuiFormHelperText-root": {
                  margin: 0,
                },
                bgcolor: "#ebf6fd",
              }}
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
            />
            {formik.touched.email && formik.errors.email && (
              <FormHelperText
                sx={{
                  margin: 0,
                  color: "red",
                  fontSize: "12px",
                }}
              >
                {formik.errors.email}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <textarea
              name="text"
              value={formik.values.text}
              onChange={formik.handleChange}
              style={{
                width: "calc(100% - 26px)",
                height: "80px",
                backgroundColor: "#ebf6fd",
                borderRadius: "4px",
                border: "1px solid #ccc",
                outline: "none",
                resize: "none",
                padding: "8px 12px",
              }}
              placeholder="Message"
            />
          </Grid>
        </Grid>

        <Box
          sx={{
            mt: "20px",
          }}
        >
          <Button type="submit" fullWidth variant="contained">
            Get In Touch
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default ContactForm;
