import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Pricing from "./view/Pricing";
import Home from "./view/Home";
import ContactPage from "./view/ContactPage";
import Features from "./view/Features";
import About from "./view/About";
import TermOfService from "./view/TermOfService";
import Privacycontent from "./view/PrivacyContent";
import tutorialRoutes from "./routes/tutorialAndHelpRoute";
import Path from "./routes/AppPaths";
import HelpDocsListPage from "./view/HelpDocsListPage";
import HelpDocsCategoryPage from "./view/HelpDocsCategorysPage";
 // Import Path object

const App = () => {
  return (
    <Router>
      <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
        <Header />
        <div style={{ flex: 1 }}>
          <Routes>
            <Route path={Path.HOME} element={<Home />} />
            <Route path={Path.PRICING} element={<Pricing />} />
            <Route path={Path.CONTACT} element={<ContactPage />} />
            <Route path={Path.FEATURES} element={<Features />} />
            <Route path={Path.ABOUT} element={<About />} />
            <Route path={Path.TERMS_OF_SERVICE} element={<TermOfService />} />
            <Route path={Path.PRIVACY_CONTENT} element={<Privacycontent />} />
            <Route path={Path.DOCS} element={<HelpDocsListPage />} /> 
            <Route path={`${Path.HELP_DOCS_CATEGORY}/:id/:name`} element={<HelpDocsCategoryPage />} />
            {tutorialRoutes}
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
