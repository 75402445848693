import React from "react";
import { Box, Typography, Link, Stack } from "@mui/material";
import { blue } from "@mui/material/colors";
import { Heading, ImageCard } from "../../../../ui-core";
import Path from "../../../../routes/AppPaths";
import TutorialAndHelpPath from "../../../../routes/tutorialAndHelpPath";

const FirebaseIntegrationGuide = () => {
  return (
    <Box pb={10}>
      <Stack spacing={3}>
        {/* Heading */}
        <Heading title="Integrate Firebase with Appcraftly for iOS & Android Apps" />

        {/* Introduction */}
        <Typography variant="h6" fontWeight="bold">
          Introduction
        </Typography>
        <Typography variant="body1">
          This guide will walk you through the process of integrating Firebase with Appcraftly for your **iOS and Android** apps. By following these steps, you'll seamlessly connect Firebase services to enhance your app’s functionality.
        </Typography>

        {/* Step 1: Integrate Firebase Account with Appcraftly Server */}
        <Typography variant="h6" fontWeight="bold">
          Step 1: Integrate Firebase Account with Appcraftly Server (Mandatory)
        </Typography>
        <Typography variant="body1">
          <strong>1. Connect Firebase Account with Appcraftly Server:</strong>
        </Typography>
        <Box component="ul">
          <li>
            Follow the instructions in the guide:{" "}
            <Link href={`${Path.TUTORIAL_PAGE}/${TutorialAndHelpPath.GET_THE_FIREBASE_ADMIN_ADK_AND_FILE_UPLOAD}`} target="_blank" sx={{ color: blue[500], fontWeight: "bold" }}>
              Click Here
            </Link>
          </li>
          <li>
            This step **must be completed** before configuring Firebase for Android or iOS.
          </li>
        </Box>

        {/* Step 2: Configuring Firebase for iOS */}
        <Typography variant="h6" fontWeight="bold">
          Step 2: Configure Firebase for iOS (Optional)
        </Typography>
        <Typography variant="body1">
          <strong>1. Connect Firebase to Appcraftly for IOS:</strong>
        </Typography>
        <Box component="ul">
          <li>
            Follow the guide:{" "}
            <Link href={`${Path.TUTORIAL_PAGE}/${TutorialAndHelpPath.CONNECT_FIREBASE_ACCOUNT_WITH_FOR_IOS}`} target="_blank" sx={{ color: blue[500], fontWeight: "bold" }}>
              connect firebase for ios
            </Link>
          </li>
          <li>
            This step establishes the connection between Firebase and your ios app.
          </li>
        </Box>

        {/* <Typography variant="body1">
          <strong>2. Connect Firebase with Apple Developer Account:</strong>
        </Typography>
        <Box component="ul">
          <li>
            Follow the guide:{" "}
            <Link
              href={`${Path.TUTORIAL_PAGE}/${TutorialAndHelpPath.CONNECT_FIREBASE_ACCOUNT_WITH__ANDROID}`}
              target="_blank"
              sx={{ color: blue[500], fontWeight: "bold" }}
            >
              connect firebase for android
            </Link>
          </li>
          <li>
            This integration allows Firebase to work with your Apple Developer Account for better app management.
          </li>
        </Box> */}

        {/* Step 3: Configuring Firebase for Android */}
        <Typography variant="h6" fontWeight="bold">
          Step 3: Configure Firebase for Android (Optional)
        </Typography>
        <Typography variant="body1">
          <strong>1. Connect Firebase to Appcraftly for Android:</strong>
        </Typography>
        <Box component="ul">
          <li>
            Follow the guide:{" "}
            <Link
              href={`${Path.TUTORIAL_PAGE}/${TutorialAndHelpPath.CONNECT_FIREBASE_ACCOUNT_WITH__ANDROID}`}
              target="_blank"
              sx={{ color: blue[500], fontWeight: "bold" }}
            >
              connect firebase for android
            </Link>
          </li>
          <li>
            This step ensures Firebase is connected to your Android app properly.
          </li>
        </Box>

        {/* Conclusion */}
        <Typography variant="h6" fontWeight="bold">
          Conclusion
        </Typography>
        <Typography variant="body1">
          By following these steps, you have successfully integrated Firebase with Appcraftly for your iOS and Android apps. This integration enables Firebase’s **powerful features** such as:
        </Typography>
        <Box component="ul">
          <li>Real-time database</li>
          <li>App analytics</li>
          <li>Push notifications</li>
          <li>Seamless backend management</li>
        </Box>
        <Typography variant="body1">
          Utilize these features to enhance your app’s performance and user experience.
        </Typography>
      </Stack>
    </Box>
  );
};

export default FirebaseIntegrationGuide;
