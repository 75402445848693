import React from 'react'
import {Container ,Box ,Typography} from '@mui/material'
const Privacycontent = () => {
  return (
    <Container maxWidth='xl'>
    <Box  marginTop='110px'paddingTop='50px' paddingBottom='30px'>
    
            <Box>
        <Typography variant='h1' sx={{ fontSize: { xs: '30px', sm: '40px' } ,fontWeight:'700'}}>Privacy Policy</Typography>
        </Box>
        <Box  marginTop='65px' padding="20px" >
   <Typography  variant='h6' sx={{ fontSize:"20px" , marginTop:'20px' , color:'grey'}}>
QuantumParadox Studio ("we" or "us" or "our") values the privacy of our users ("user" or "you"). This Privacy Policy outlines how we gather, utilize, reveal, and protect your information when you utilize our mobile application (the "Application"). We urge you to carefully review this Privacy Policy.</Typography>
    <Typography variant='h6' sx={{ fontSize:"20px" , marginTop:'20px' , color:'grey'}}>We retain the right to modify this Privacy Policy at any time and for any purpose. We will notify you of any changes by updating the "Last updated" date of this Privacy Policy. It is recommended that you regularly review this Privacy Policy to remain informed of any updates. By continuing to use the Application after the revised Privacy Policy is posted, you will be considered to have been informed of, subject to, and accepted the changes in any revised Privacy Policy.</Typography>
    <Typography variant='h6' sx={{ fontSize:"20px" , marginTop:'20px' , color:'grey'}}>This Privacy Policy does not extend to the third-party online/mobile store from which you download the Application or conduct transactions, including purchases of in-game virtual items, which may gather and utilize data about you. We do not take responsibility for any data collected by such third parties.</Typography>
    </Box>
    <Box  marginTop='65px'>
        <Typography variant='h1' sx={{ fontSize: { xs: '20px', sm: '30px' } ,fontWeight:'700' }}>SECURITY OF YOUR INFORMATION</Typography>
        <Typography  variant='h6' sx={{ fontSize:"20px" , marginTop:'20px' , color:'grey'}}>
We employ administrative, technical, and physical security measures to assist in safeguarding your personal information. Despite our diligent efforts, it's important to recognize that no security measures are entirely infallible, and no method of data transmission can be guaranteed against interception or misuse. Any information disclosed online is susceptible to interception and misuse by unauthorized parties. Consequently, we cannot ensure absolute security in the event that you provide personal information.</Typography>

    </Box>
    <Box  marginTop='65px'>
        <Typography variant='h1' sx={{ fontSize: { xs: '20px', sm: '30px' } ,fontWeight:'700' }}> 

POLICY FOR CHILDREN</Typography>
        <Typography  variant='h6' sx={{ fontSize:"20px" , marginTop:'20px' , color:'grey'}}>
We do not intentionally request information from or market to children under the age of 13. If you become aware of any data we have collected from children under the age of 13, please contact us using the provided contact information below.</Typography>
        
    </Box>
    <Box marginTop='65px'>
        <Typography variant='h1' sx={{ fontSize: { xs: '20px', sm: '30px' },fontWeight:'700' }}>CONTROLS FOR DO-NOT-TRACK FEATURES</Typography>
        <Typography  variant='h6' sx={{ fontSize:"20px" , marginTop:'20px' , color:'grey'}}>Many web browsers and some mobile operating systems offer a Do-Not-Track ("DNT") feature or setting that allows you to indicate your preference for privacy by avoiding the monitoring and collection of data about your online browsing activities. However, there is no standardized technology protocol established for recognizing and implementing DNT signals. Therefore, at present, we do not respond to DNT browser signals or any other mechanism that automatically communicates your decision not to be tracked online. If a standard for online tracking is adopted in the future that we are required to adhere to, we will notify you about this practice in an updated version of this Privacy Policy.</Typography>
   
    </Box>
    <Box  marginTop='65px'>
        <Typography variant='h1' sx={{ fontSize: { xs: '20px', sm: '30px' } ,fontWeight:'700' }}>CALIFORNIA PRIVACY RIGHTS</Typography>
        <Typography  variant='h6' sx={{ fontSize:"20px" , marginTop:'20px' , color:'grey'}}>California Civil Code Section 1798.83, commonly referred to as the "Shine The Light" law, grants our California resident users the right to request and receive, once a year and free of charge, information about the categories of personal information (if any) we have disclosed to third parties for direct marketing purposes, as well as the names and addresses of all third parties with whom we have shared personal information in the preceding calendar year. If you are a California resident and wish to make such a request, please submit your request in writing to us using the contact information provided below.</Typography>
        <Typography  variant='h6' sx={{ fontSize:"20px" , marginTop:'20px' , color:'grey'}}>If you are under 18 years of age, reside in California, and have a registered account with the Application, you have the right to request the removal of any unwanted data that you have publicly posted on the Application. To request the removal of such data, please contact us using the provided contact information below, and include the email address linked to your account along with a statement indicating your residency in California. While we will ensure that the data is no longer publicly displayed on the Application, please note that the data may not be entirely or comprehensively removed from our systems.</Typography>
    </Box>
    <Box  marginTop='65px'>
        <Typography variant='h1' sx={{ fontSize: { xs: '20px', sm: '50px' } ,fontWeight:'700'}}></Typography>
        <Typography  variant='h6' sx={{ fontSize:"20px" , marginTop:'20px' , color:'grey'}}></Typography>
        <Typography  variant='h6' sx={{ fontSize:"20px" , marginTop:'20px' , color:'grey'}}></Typography>
    </Box>
    </Box>
    </Container>
  )
}

export default Privacycontent