import React from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  Paper,
  IconButton,
} from "@mui/material";

import MoodIcon from "@mui/icons-material/Mood";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CloseIcon from "@mui/icons-material/Close";
import MultiPayment from "../../assests/cards.png";
import Premiumm from "../../assests/crown.png";
import gold from "../../assests/premium.png";

import Model from "../../components/Model/index";
import TrustedGrowing from "../../components/Trusted-Growing";
import { fontSize, lineHeight } from "@mui/system";

const styles = {
  paper: {
    textAlign: "left",
    padding: {xs:"20px",md:"80px",lg:"80px"},
    borderRadius: "30px",
  },
  iconButton: {
    color: "inherit",
    fontSize: "50",
  },
  title: {
    marginTop: "10px",
    textTransform: "capitalize",
    fontWeight: "700",
    fontSize:{xs:"30px",sm:"3rem",md:"3rem",lg:"3rem"},
    
  },
  description: {
    marginTop: "10px",
    textTransform: "capitalize",
    fontSize: "14px",
  },
  info: {
    marginTop: "25px",
    textTransform: "uppercase",
    fontSize: "15px",
    color: "grey",
  },
  value: {
    marginTop: "5px",
    textTransform: "capitalize",
    fontSize:{xs:"15px",md:"25px",lg:"25px",sm:"25px"}, 
    fontWeight: "700",
  },
  button: {
    marginTop: "30px",
    width: {xs:"137px",md:"180px",lg:"180px",sm:"180px"}, 
    height: "60px",
    fontWeight: "700",
  },
};

const Pricing = () => {
  //
  const [openModel, setOpenModel] = React.useState(false);

  const handleOpenModel = () => {
    setOpenModel(true);
  };

  const handleCloseModel = () => {
    setOpenModel(false);
  };

  const pricingOptions = [
    {
      img: MultiPayment,
      title: "Grow Plan",
      description: <div className="price_list"><ul><li>Wishlist</li><li>Filtering</li><li>Secure payment gateway</li><li>Multi currency</li><li>Multiple payment options</li><li>Sorting</li><li>24x7 Email support</li></ul></div>,
      timeToMarket: "1 week",
    },
    {
      img: Premiumm,
      title: "Scale Plan",
      description:  <div className="price_list"><ul><li>Grow plan</li><li>Product reviews by judgeme</li><li>Push Notifications</li><li>Dedicated CSM</li><li>One page checkout</li><li>Deeplinking</li><li>Discounlty by Anncode</li></ul></div>,
      timeToMarket: "1-2 weeks",
    },
  ];



  return (
    <div>
      <Container maxWidth="xl">
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8} lg={16}>
            <Box
              sx={{
                backgroundColor: "white",
                color: "black",
                textAlign: "center",
                padding:{xs:"20px",sm:"80px",md:"80px"},
              
              }}
               
              
            >
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: "24px", sm: "32px", md: "60px" }, // Responsive font size
                  fontWeight: "600",
                }}
              >
                Native mobile app
                <br /> development in each plan!
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontSize: { xs: "16px", md: "18px" },
                  marginTop: "20px",
                  color: "grey",
                }}
              >
                Triple your mobile conversion rate and increase revenue with
                AnnaCode's Shopify mobile app plans. Our dedicated success
                program
                <br /> is designed to deliver maximum value and optimize returns
                for your business.
              </Typography>
            </Box>
          </Grid>

          <Grid container spacing={2} sx={{ padding:{xs:"20px",sm:"40px", md:"40px"}   }}>
            {pricingOptions.map((option, index) => (
              <Grid item xs={12} sm={6} md={6} key={index}>
                <Paper
                  elevation={3}
                  sx={{
                    ...styles.paper,
                    backgroundColor:
                    
                      index === 1 ? "#D1FFBD" : index === 2 ? "#FFDC73" : "",
                     
                  }}
                >
                  <Typography variant="h3" sx={styles.title}>
                    {option.title}
                  </Typography>
                  <Typography variant="h6" sx={styles.description}>
                    {option.description}
                  </Typography>
                  <Typography variant="h5" sx={styles.info}>
                    Time to market
                  </Typography>
                  <Typography variant="h5" sx={styles.value}>
                    {option.timeToMarket}
                  </Typography>
                  <Button
                    onClick={() => handleOpenModel()}
                    sx={styles.button}
                    variant="contained"
                  >
                    Book a demo
                  </Button>
                </Paper>
              </Grid>
            ))}
          </Grid>
          </Grid>
      </Container>
      {openModel && (
        <Model modalClose={handleCloseModel} open={handleOpenModel} />
      )}
      {/* <TrustedGrowing /> */}
    </div>
  );
};

export default Pricing;
