import React from 'react';
import { Box, Typography, Stack } from "@mui/material";
import { Heading, ImageCard } from "../../../../ui-core";
import { 
  AppConfigsPage, 
  AppsAndSaleChannelSettings, 
  clickInstall, 
  ClickOnTheAppSection, 
  ConfigureStorefrontAPIscopes, 
  copyTheStorefrontToken, 
  CreateAnApp, 
  DevelopsApp, 
  FillAndSave, 
  FillTheNameAndEmail, 
  installAppButton, 
  selectAllScopes 
} from './images';

const StorefrontToken = () => {
  return (
    <Box pb={10}>
      <Stack spacing={3}>
        <Heading title="Get the Storefront Token for the Appcraftly Application" />

        {/* Step 1: Navigate to Store Admin */}
        <Typography variant="h6" fontWeight="bold">
          Step 1: Go to your store admin
        </Typography>
        <Typography variant="body1">
          Then, click on the <strong>Apps</strong> section.
        </Typography>
        <ImageCard image={ClickOnTheAppSection} alt="Store Admin Apps Section" />

        {/* Step 2: Apps and Sale Channel Settings */}
        <Typography variant="h6" fontWeight="bold">
          Step 2: Click on <strong>Apps and Sale Channel Settings</strong>
        </Typography>
        <ImageCard image={AppsAndSaleChannelSettings} alt="Apps and Sale Channel Settings" />

        {/* Step 3: Navigate to Develop Apps Section */}
        <Typography variant="h6" fontWeight="bold">
          Step 3: Click on <strong>Develop Apps</strong>
        </Typography>
        <ImageCard image={DevelopsApp} alt="Develop Apps Section" />

        {/* Step 4: Create an App */}
        <Typography variant="h6" fontWeight="bold">
          Step 4: Click on the <strong>Create an App</strong> button in the top right corner.
        </Typography>
        <ImageCard image={CreateAnApp} alt="Create an App Button" />

        {/* Step 5: Provide App Details */}
        <Typography variant="h6" fontWeight="bold">
          Step 5: Fill in the <strong>Application Name</strong> and select your <strong>Email</strong>
        </Typography>
        <Typography variant="body1">
          Enter <strong>Appcraftly Storefront</strong> as the application name, select your email, and click on the <strong>Create App</strong> button.
        </Typography>
        <ImageCard image={FillTheNameAndEmail} alt="Fill App Name and Email" />

        {/* Step 6: Configure API Scopes */}
        <Typography variant="h6" fontWeight="bold">
          Step 6: Click on the <code>Configure Storefront API Scopes</code> button.
        </Typography>
        <ImageCard image={ConfigureStorefrontAPIscopes} alt="Configure API Scopes" />

        {/* Step 7: Select API Scopes */}
        <Typography variant="h6" fontWeight="bold">
          Step 7: Select all <code>Storefront API Access Scopes</code> from the list.
        </Typography>
        <Typography variant="body1">
          After selecting all the <strong>Storefront API Access Scopes</strong>, click on the <strong>Save</strong> button.
        </Typography>
        <ImageCard image={selectAllScopes} alt="Select API Scopes" />

        {/* Step 8: Install the App */}
        <Typography variant="h6" fontWeight="bold">
          Step 8: After saving, click on the <strong>Install App</strong> button in the top right corner.
        </Typography>
        <ImageCard image={installAppButton} alt="Install App Button" />

        {/* Step 8.1: Confirm Installation */}
        <Typography variant="h6" fontWeight="bold">
          Step 8.1: Click on <strong>Install</strong> to confirm and proceed.
        </Typography>
        <ImageCard image={clickInstall} alt="Confirm Install" />

        {/* Step 9: Copy Storefront Access Token */}
        <Typography variant="h6" fontWeight="bold">
          Step 9: Copy the <strong>Storefront Access Token</strong>
        </Typography>
        <ImageCard image={copyTheStorefrontToken} alt="Copy Storefront Token" />

        {/* Step 10: Add Token to Appcraftly */}
        <Typography variant="h6" fontWeight="bold">
          Step 10: Open the <strong>Appcraftly Application</strong> and navigate to the <code>App Configs</code> screen.
        </Typography>
        <ImageCard image={AppConfigsPage} alt="Appcraftly App Configs Page" />
        <Typography variant="body1">
          Paste the copied token in the <strong>Storefront Access Token</strong> section and click on the <strong>Save</strong> button.
        </Typography>
        <ImageCard image={FillAndSave} alt="Paste and Save Token" />
      </Stack>
    </Box>
  );
};

export default StorefrontToken;