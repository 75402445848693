import React from "react";
import { Stack, Box, Typography, Link } from "@mui/material";
import { Heading, ImageCard } from "../../../../ui-core";
import { blue } from "@mui/material/colors";
import { AddSha1Configs, ClickOnTheProjectSettings, CopyTheShaConfigs, FireBaseConsole, navigateYourApps, OpenTheAppCraftly, ReqForShaConfigs, Sha256 } from "./images";

export default function UpdateShaConfigsIntoFireBase() {
  return (
    <Box pb={20}>
      <Stack spacing={3}>

        {/* Heading Section */}
        <Heading title="Update SHA Configs in Your Firebase App" />

        {/* Step 1 */}
        <Typography variant="h6">Step 01: Open the Appcraftly App</Typography>
        <Typography variant="body1">
          Navigate to the <code>Publishing</code> page.
        </Typography>
        <ImageCard image={OpenTheAppCraftly} alt="Appcraftly Publishing Page" />

        {/* Step 2 */}
        <Typography variant="h6">Step 02: Request SHA Configs</Typography>
        <Typography variant="body1">
          Click on the <code>Android Application</code> button, then select <code>Request for SHA Configs</code>.
        </Typography>
        <ImageCard image={ReqForShaConfigs} alt="Request SHA Configs Page" />

        {/* Step 3 (Duplicate of Step 2) */}
        {/* Consider removing this if it was unintentional. */}
        <Typography variant="h6">Step 03: Request SHA Configs Again (If Needed)</Typography>
        <Typography variant="body1">
          If necessary, repeat the process by clicking on the <code>Android Application</code> button and selecting <code>Request for SHA Configs</code>.
        </Typography>
        <ImageCard image={ReqForShaConfigs} alt="Request SHA Configs Page" />

        {/* Step 4 */}
        <Typography variant="h6">Step 04: Copy the SHA-1 and SHA-256 Configs</Typography>
        <Typography variant="body1">
          After the SHA configs are generated, copy both the <code>SHA-1</code> and <code>SHA-256</code> values.
        </Typography>
        <ImageCard image={CopyTheShaConfigs} alt="Copy SHA Configs" />

        {/* Step 5 */}
        <Typography variant="h6">Step 05: Open the Firebase Console</Typography>
        <Typography variant="body1">
          Go to the{" "}
          <Link href="https://console.firebase.google.com" target="_blank" sx={{ color: blue[500], fontWeight: "bold" }}>
            Firebase Console
          </Link>
          , and open the Firebase project that was created for the Appcraftly App.
        </Typography>
        <ImageCard image={FireBaseConsole} alt="Firebase Console" />

        {/* Step 6 */}
        <Typography variant="h6">Step 06: Access Project Settings</Typography>
        <Typography variant="body1">
          Click on <code>Project Settings</code> in the Firebase console.
        </Typography>
        <ImageCard image={ClickOnTheProjectSettings} alt="Project Settings" />

        {/* Step 7 */}
        <Typography variant="h6">Step 07: Navigate to Your Apps</Typography>
        <Typography variant="body1">
          Go to the <strong>Your Apps</strong> section, then select your Android app. Click on the <strong>Add Fingerprint</strong> button.
        </Typography>
        <ImageCard image={navigateYourApps} alt="Your Apps Section" />

        {/* Step 8 */}
        <Typography variant="h6">Step 08: Add the Copied SHA Configs</Typography>
        <Typography variant="body1">
          Paste the <code>SHA-1</code> config and save it.
        </Typography>
        <ImageCard image={AddSha1Configs} alt="Add SHA-1 Config" />

        <Typography variant="body1">
          Then, paste the <code>SHA-256</code> config and save it.
        </Typography>
        <ImageCard image={Sha256} alt="Add SHA-256 Config" />
      </Stack>
    </Box>
  );
}
