import React from "react";
import { Box, Typography, Link, Stack } from "@mui/material";
import { blue } from "@mui/material/colors";
import { Heading } from "../../../../ui-core";

const PublishingAndroidAppForFirstTime = () => {
  return (
      <Box pb={10}>
        <Stack spacing={3}>
          <Heading title="Publish Your First Android App on Google Play Store" />

          <Typography variant="body1">
            Publishing your app on the Google Play Store is an exciting step toward reaching a wider audience. This guide will walk you through the process of uploading and publishing your Android app effortlessly.
          </Typography>

          <Typography variant="body1" fontWeight="bold">
            Before You Begin
          </Typography>
          <Typography variant="body1">
            Make sure you have the following ready:
            <ul>
              <li>An Android developer account with Google Play Console.</li>
              <li>A fully tested and signed APK or AAB file.</li>
              <li>App details such as name, description, screenshots, and feature graphics.</li>
              <li>A privacy policy URL (if applicable).</li>
              <li>A payment profile if your app includes in-app purchases.</li>
            </ul>
          </Typography>

          {/* Step 1 */}
          <Typography variant="body1">
            <strong>Step 1: Connect Your Developer Account 🔗</strong>
          </Typography>
          <Typography variant="body1">
            Before publishing your app, ensure you have an active Android developer account. If you haven’t created one yet, follow these steps to set it up:
            <ul>
              <li>Go to the <Link href="https://play.google.com/console" target="_blank" sx={{ color: blue[500], fontWeight: "bold" }}>Google Play Console</Link>.</li>
              <li>Sign in with your Google account and complete the registration process.</li>
              <li>Pay the one-time registration fee.</li>
            </ul>
          </Typography>

          {/* Step 2 */}
          <Box>
            <Stack spacing={2}>
              <Typography variant="body1">
                <strong>Step 2: Upload Your App to the Google Play Store 📤</strong>
              </Typography>
              <Typography variant="body1">
                Once your developer account is ready, follow these steps to upload your app to the Google Play Store:
              </Typography>
              <ul>
                <li>Log in to the <Link href="https://play.google.com/console" target="_blank" sx={{ color: blue[500], fontWeight: "bold" }}>Google Play Console</Link>.</li>
                <li>Click on “Create App” and provide your app details.</li>
                <li>Upload your APK or AAB file.</li>
                <li>Fill out the content rating questionnaire.</li>
                <li>Set up pricing and distribution settings.</li>
              </ul>
              <Typography variant="body1">
                Here our instructions, to upload build on google play store👉
                <Link href="https://appcraftly.anncode.com/TutorialPage/upload-an-app-to-the-google-play-store" target="_blank" sx={{ color: blue[500], fontWeight: "bold" }}>
                  instructions
                </Link>
              </Typography>
              <Typography variant="body1">
                For detailed instructions, access 👉
                <Link href="https://support.google.com/googleplay/android-developer" target="_blank" sx={{ color: blue[500], fontWeight: "bold" }}>
                  Google Play Console Help Center
                </Link>
              </Typography>
            </Stack>
          </Box>

          {/* Step 3 */}
          <Box>
            <Typography variant="body1">
              <strong>Step 3: App Review and Publication ✅</strong>
            </Typography>
            <Typography variant="body2" sx={{ marginTop: 1 }}>
              After submitting your app, it will undergo a review by the Google Play Store team. Here's what to expect:
            </Typography>
            <ul>
              <li>The review process typically takes 4 to 7 business days.</li>
              <li>You can monitor the progress on your Google Play Console dashboard.</li>
              <li>If there are any issues, follow the provided feedback to make necessary adjustments and resubmit your app.</li>
              <li>Once approved, your app will be live on the Google Play Store!</li>
              <li>You can enable email notifications for status updates.</li>
            </ul>

            {/* Final Message */}
            <Typography variant="h6" fontWeight="bold" sx={{ marginTop: 3 }}>
              🎉 Congratulations!
            </Typography>
            <Typography variant="body2" sx={{ marginTop: 1 }}>
              Your Android app is now published on the Google Play Store.
            </Typography>
            <Typography variant="body2" sx={{ marginTop: 1 }}>
              Be sure to regularly update your app with new features, improvements, and bug fixes to keep your users engaged.
            </Typography>
            <Typography variant="body2" sx={{ marginTop: 1 }}>
              Need assistance? Our support team is here to help. Visit the <Link href="https://support.google.com/googleplay/android-developer" target="_blank" sx={{ color: blue[500], fontWeight: "bold" }}>Google Play Developer Support</Link> for further guidance.
            </Typography>
            <Typography variant="body1" fontWeight="bold" sx={{ marginTop: 2, marginBottom: 10 }}>
              Happy Publishing! 🚀
            </Typography>
          </Box>
        </Stack>
      </Box>
  );
};

export default PublishingAndroidAppForFirstTime;
