import React from 'react'
import AboutUs from '../../components/AboutUs'
import AppcraftlyAbout from '../../components/AppcraftlyAbout/Index'
const About = () => {
  return (
  <div>
    <AboutUs/>
<AppcraftlyAbout/>
  </div>
  )
}

export default About