const TutorialAndHelpPath = {

  //  Invite App Manager for Assistance
  INVITE_GOOGLE_PLAY_CONSOLE: "invite-appcraftly-app-manager-to-my-android-developer-account-(Google-Play-Console)",
  INVITE_APP_STORE_CONNECT: "invite-appcraftly-app-manager-to-my-apple-developer-account-(App-Store-Connect).",
  //Docs 
  CONNECT_FIREBASE_ACCOUNT_WITH__ANDROID: "connect-firebase-account-with-appcraftly-app-for-android",
  CONNECT_FIREBASE_ACCOUNT_WITH_FOR_IOS: "connect-firebase-account-with-appcraftly-app-for-ios",
  DYNAMIC_LINK_ANDROID_IOS: "enable-dynamic-link-for-iOS-and-android",
  GET_APP_ID_FOR_IOS: "get-the-appId-for-ios-in-the-deep-link",
  GET_THE_FIREBASE_ADMIN_ADK_AND_FILE_UPLOAD: "get-the-firebase-admin-adk-file-and-upload-file",
  RENEW_YOUR_IOS_DISTRIBUTION_APPLE_2025: "how-to-renew-your-ios-distribution-or-development-certificate-on-apple-2025",
  UPLOAD_AN_APP_TO_THE_GOOGLE_PLAY_STORE: "upload-an-app-to-the-google-play-store",
  UPLOAD_AN_APP_TO_THE_APPLE_APP_STORE: "upload-an-app-to-the-apple-app-store",
  //App Integrations
  INTEGRATION_FIREBASE_FOR_IOS_ANDROID: "integrate-firebase-with-appcraftly-and-configure-for-iOS-and-android-of-appcraftly-apps",
  JUDGE_ME_IMPLEMENTATION: "integrate-the-judge-me-review-app-into-the-mobile-app",

  //App Publishing
  PUBLISHING_ANDROID_APP_FIRST_TIME_WITH_APPCRAFTLY: "publishing-android-app-for-the-first-time-with-appcraftly",
  PUBLISHING_IOS_APP_FIRST_TIME_WITH_APPCRAFTLY: "publishing-ios-app-for-the-first-time-with-appcraftly",

  GET_THE_STOREFRONT_STORE_TOKEN:"get-the-store-frontstore-token",
  UPDATE_SHA_CONFIGS_IN_THE_FIREBASE:"update-the-sha-congfigs-in-firebase"

};

export default TutorialAndHelpPath;
