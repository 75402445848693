import React from "react";
import { Box, Typography, Link, Stack } from "@mui/material";
import { blue } from "@mui/material/colors";
import { Heading, ImageCard } from "../../../../ui-core";
import { InviteAppManagerForAssistance } from "../../../../content/HelpDocs";
import { AddUser, Login, selectPermission, UserAndAccess } from "./images";

const AppleDeveloperAccountAppStoreConnect = () => {
  return (
    <Box pb={10}>
      <Stack spacing={3}>
        <Heading title={InviteAppManagerForAssistance[1].name} />

        <Typography variant="body1">
          Follow these steps to invite AppCraftly as an App Manager in your Apple Developer Account (App Store Connect).
        </Typography>

        <Typography variant="body1">
          1. Log in to <Link href="https://appstoreconnect.apple.com/" target="_blank" sx={{ color: blue[500], fontWeight:"bold"}}>App Store Connect</Link>.
        </Typography>
        <ImageCard image={Login} alt="App Store Connect sign-in page" />

        <Typography variant="body1">
          2. Navigate to the <strong>Users and Access</strong> section.
        </Typography>
        <ImageCard image={UserAndAccess} alt="Navigate Users and Access" />

        <Typography variant="body1">
          3. Click the <strong>“+”</strong> button at the top-left corner of the page inside the "People" tab.
        </Typography>
        <ImageCard image={AddUser} />

        <Typography variant="body1">
          4. Fill this details in he New User From.
        </Typography>

        <Box component="ul" sx={{ pl: 3 }}>
          <Typography component="li">
            <strong>First Name:</strong> AppCraftly
          </Typography>
          <Typography component="li">
            <strong>Last Name:</strong> App Manager
          </Typography>
          <Typography component="li">
            <strong>Email:</strong> rahul@anncode.in
          </Typography>
          <Typography component="li">
            Assign the <strong>Admin</strong> role.
          </Typography>
          <Typography component="li">
            Click <strong>Invite</strong> to send the invitation.
          </Typography>
        </Box>
        <ImageCard image={selectPermission} alt="Select permissions screen" />
        <Typography variant="body1">
          {"5. Next -> Next -> Next."}
        </Typography>
      </Stack>
    </Box>
  );
};

export default AppleDeveloperAccountAppStoreConnect;
