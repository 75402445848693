import React from "react";
import { Box, Typography, Link, Stack } from "@mui/material";
import { blue } from "@mui/material/colors";
import { Heading, ImageCard } from "../../../../ui-core";
import {
   FillTheDetails,
   GeneralSettings,
   InstallJudgeMe,
   JudgeMeHome,
   MoveToTheAppCraftlySettings,
   SettingsPage,
   StartSetUp,
   StepFourContinue,
   StepOneContinue,
   StepThreeContinue,
   StepTwoContinue,
   Tokens,
} from "./images";

const JudgeMeDocs = () => {
   return (
      <Box pb={10}>
         <Stack spacing={3}>
            <Heading title="How to Connect the Judge.me Product Reviews App in the appCraftly" />

            <Typography variant="body1">
               1. Open the Shopify App Store and search for 
               <Link href='https://apps.shopify.com/judgeme' target="_blank" sx={{ color: blue[500], fontWeight: "bold" }}>Judge.me Product Reviews App</Link>.
            </Typography>

            <Typography variant="body1">
               2. Install the Judge.me Product Reviews App in your Shopify store.
            </Typography>
            <ImageCard image={InstallJudgeMe} alt="Install Judge.me" />

            <Typography variant="body1">
               3. After installation, click the <strong>Start Setup</strong> button and follow the on-screen instructions.
            </Typography>
            <ImageCard image={StartSetUp} alt="Start Setup" />

            <Typography variant="body1">
               4. Enable the embedded app functionality and proceed to the next step.
            </Typography>
            <ImageCard image={StepOneContinue} alt="Enable Embedded App" />

            <Typography variant="body1">
               5. Install the review widget and continue to the next step.
            </Typography>
            <ImageCard image={StepTwoContinue} alt="Install Review Widget" />

            <Typography variant="body1">
               6. Click the <strong>Continue</strong> button to proceed.
            </Typography>
            <ImageCard image={StepThreeContinue} alt="Continue Setup" />

            <Typography variant="body1">
               7. Click the <strong>Finish</strong> button to complete the setup and navigate to the home page.
            </Typography>
            <ImageCard image={StepFourContinue} alt="Finish Setup" />

            <Typography variant="body1">
               8. Once on the home page, go to the <strong>General Settings</strong> page.
            </Typography>
            <ImageCard image={JudgeMeHome} alt="General Settings" />

            <Typography variant="body1">
               9. Click on the <strong>Integrations</strong> tab, then select the <strong>View API Token</strong> button.
            </Typography>
            <ImageCard image={GeneralSettings} alt="View API Token" />

            <Typography variant="body1">
               10. Copy your <strong>Public</strong> and <strong>Private</strong> API tokens for future use.
            </Typography>
            <ImageCard image={Tokens} alt="API Tokens" />

            <Typography variant="body1">
               11. Open the <strong>AppCraftly App</strong> and navigate to the <strong>Settings</strong> page.
            </Typography>
            <ImageCard image={MoveToTheAppCraftlySettings} alt="AppCraftly Settings" />

            <Typography variant="body1">
               12. Click on the <strong>Judge.me</strong> tab.
            </Typography>
            <ImageCard image={SettingsPage} alt="Judge.me Tab" />

            <Typography variant="body1">
               13. Enter the copied <strong>Public</strong> and <strong>Private</strong> API tokens into the respective fields.
            </Typography>
            <ImageCard image={FillTheDetails} alt="Enter API Tokens" />
         </Stack>
      </Box>
   );
};

export default JudgeMeDocs;